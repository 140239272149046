.cursor-pointer {
  cursor: pointer !important;
}

.loader {
  position: relative;
  width: 150px;
  /* Enlarge the loader container */
  height: 150px;
  /* Enlarge the loader container */
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 30px;
  /* Adjust the size of the dots */
  height: 30px;
  /* Adjust the size of the dots */
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.5);
  /* Red with gradient */
  animation: ripple 1.5s linear infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 0.8s;
}

.dot:nth-child(4) {
  animation-delay: 1s;
}

.parent-div {
  display: flex;
  /* Use Flexbox for dynamic alignment */
  justify-content: space-between;
  /* Space between the buttons */
  align-items: center;
  /* Vertically center the buttons if needed */
  padding: 10px;
  padding-bottom: 10px;
  /* Optional padding */
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }

  50% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
  }
}