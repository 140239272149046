.u-gallery-nav-next,
.u-gallery-nav-prev {
  display: none !important;
}

.captain {
  font-family: 'American-Captain', sans-serif;
  letter-spacing: 5px;
}

@font-face {
  font-family: 'American-Captain';
  src: url('/public/html/fonts/american_captain_o.ttf') format('truetype');
}


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



@media only screen and (max-width: 768px) {
  .navright {
    visibility: visible !important;
  }

  .sphead {
    font: 700 1.6em/1 "American-Captain", sans-serif !important;
    margin-top: 1rem !important;
  }

  .phead {
    font: 700 1em/1 "American-Captain", sans-serif !important;
  }

  .simp-btn {
    display: block !important;
  }
}

.effh2 {
  font-weight: 300;
  animation: lights 5s 750ms linear infinite;
}

@keyframes lights {
  0% {
    color: hsl(230, 40%, 80%);
    text-shadow:
      0 0 1em hsla(320, 100%, 50%, 0.2),
      0 0 0.125em hsla(320, 100%, 60%, 0.3),
      -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
      1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
  }

  30% {
    color: hsl(230, 80%, 90%);
    text-shadow:
      0 0 1em hsla(320, 100%, 50%, 0.5),
      0 0 0.125em hsla(320, 100%, 60%, 0.5),
      -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
      0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
  }

  40% {
    color: hsl(230, 100%, 95%);
    text-shadow:
      0 0 1em hsla(320, 100%, 50%, 0.5),
      0 0 0.125em hsla(320, 100%, 90%, 0.5),
      -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
      0.25em 0.125em 0.125em hsla(200, 100%, 60%, 0.4);
  }

  70% {
    color: hsl(230, 80%, 90%);
    text-shadow:
      0 0 1em hsla(320, 100%, 50%, 0.5),
      0 0 0.125em hsla(320, 100%, 60%, 0.5),
      0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
      -0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
  }

  100% {
    color: hsl(230, 40%, 80%);
    text-shadow:
      0 0 1em hsla(320, 100%, 50%, 0.2),
      0 0 0.125em hsla(320, 100%, 60%, 0.3),
      1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
      -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
  }
}

.b-flr {
  background-color: rgba(69, 160, 235, 0.75) !important;
  color: black !important;
  padding: 15px 37px !important;
}

.b-sgb {
  background-color: rgba(234, 199, 67, 0.75) !important;
  color: black !important;
}

.simp-btn {
  font: 700 0.8em "Oswald", sans-serif;
  text-decoration: none;
  text-transform: capitalize;
  border-radius: 23px;
  letter-spacing: 0;
  border: none;
  border: 2px solid #000;
  padding: 15px;
  cursor: pointer;
}

.simp-btn:hover {
  border: 2px solid #fff;
}

.textred {
  color: #ff3333;
}

.textgreen {
  color: #00cc33;
}

.content2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: #ecf0f1;
  margin: auto;
}

.content__container2 {
  font-weight: 600;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 15px;
}

.navright {
  font-weight: 400;
  color: #aaa;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  visibility: hidden;
  text-align: right;
  padding: 15px;
}

.content {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  color: #ecf0f1;
}

.phead {
  /*font: 700 1em/1 "Oswald", sans-serif;*/
  font: 400 1.2em/1 "American-Captain", sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  display: block;
  border: 4px double rgba(255, 255, 255, .25);
  border-width: 0 0 4px;
  padding: 1.5em 0em;
  /*margin: 1em 1em;*/
}

.sphead {
  /*font: 700 2.7em/1 "Oswald", sans-serif;*/
  font: 700 3.5em/1 "American-Captain", sans-serif;
  letter-spacing: 5px;
  padding: .25em 0 .325em;
  display: block;
  margin: 0 auto;
  text-shadow: 0 0 80px rgba(248, 41, 41, 0.5);

  /*background: url(images/photo-1529641484336-ef35148bab06.avif) repeat-y;*/
  background: url(/public/html/images/gif13.gif) repeat-y;

  -webkit-text-fill-color: transparent;
  background-size: 500px;
  -webkit-background-size: 800px;
  background-repeat: repeat-x;
  -webkit-background-repeat: repeat-x;
  background-clip: text;
  -webkit-background-clip: text;

  -webkit-animation: satrapsf 80s linear infinite;
  animation: satrapsf 80s linear infinite;
}

.u-section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.h-full {
  height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.transition-all {
  transition: all 0.5s ease-in-out;
}

/* Wrapper to center the bookcase */
.bookcase-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  margin-bottom: 30px;
  background-color: #00000000;
}

/* Container for all the panes */
.bookcase-container {
  display: flex;
  max-width: 80%;
  height: 400px;
  gap: 10px;
}

/* Individual pane */
.book-pane {
  flex: 0 0 10%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  cursor: pointer;
  transition: flex 0.7s ease-in-out;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  padding: 10px;
}

/* Expanded pane */
.book-pane.active {
  flex: 0 0 60%;
}

.title-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out, opacity 0.5s ease;
}

/* Expanded state: icon moves to the left of the title */
/* .title-container.expanded {} */

/* Icon Styling */
.pane-icon {
  width: 40px;
  height: 40px;
  color: white;
  background-color: rgba(185, 2, 2, 0.7);
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pane-title {
  animation: lights 5s 750ms linear infinite;
  font-size: 1.1rem;
  color: white;
  white-space: nowrap;
  margin-left: 8px;
}

.book-pane:not(.active) .pane-title {
  display: none;
}

/* Pane Content */
.pane-content {
  opacity: 0;
  max-height: 0;
  transition: opacity 0.5s ease, max-height 0.5s ease-in-out;
  padding-top: 8rem;
  padding-left: 100px;
  padding-right: 100px;
  word-wrap: break-word;
  color: white;
  overflow: hidden;
}

/* Show content when expanded */
.pane-content.show {
  opacity: 1;
  max-height: 100%;
  transition: opacity 1.3s ease-in-out, max-height 0.5s ease-in-out;
}

.btn-primary {
  background-color: #8d0101;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
}

.btn-primary:hover {
  background-color: #ff1751;

}

.btn-secondary {
  background-color: #4b5563;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
}

.btn-secondary:hover {
  background-color: #374151;
}

.btn-primary,
.btn-secondary {
  display: inline-block;
  text-align: center;
}

.u-footer {
  flex-shrink: 0;
  margin-top: auto;
}

.u-footer .u-sheet-1 {
  min-height: 187px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .pane-content {
    padding-top: 40%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .sphead {
    font: 700 2.2em/1 "American-Captain", sans-serif !important;
    margin-top: 1rem !important;
  }
}

@media (max-width: 768px) {

  .bookcase-wrapper {
    margin-bottom: 50px;
  }

  /* vertically stacking the books */
  .bookcase-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: auto;
  }

  .book-pane {
    flex: 0 0 auto;
    width: 100%;
    max-height: 80px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.9;
  }

  .book-pane.active {
    max-height: 300px;
    opacity: 1;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  /* Center the icon in collapsed state */
  .title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    transition: left 0.5s ease, transform 0.5s ease, justify-content 0.5s ease;
    width: 100%;
  }

  /* When pane is expanded, icon moves to the left */
  .title-container.expanded {
    left: 10px;
    transform: translateX(0);
    justify-content: flex-start;
  }

  .pane-title {
    font-size: 1.5rem;
    color: white;
    margin-left: 5%;
    transition: transform 0.5s ease;
  }

  .pane-icon {
    width: 30px;
    height: 30px;
    padding: 4px;
    transition: transform 0.5s ease;
  }

  .pane-content {
    opacity: 0;
    max-height: 0;
    padding-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
    transition: opacity 0.5s ease, max-height 0.5s ease-in-out;
    overflow: hidden;
  }


  .pane-content.show {
    opacity: 1;
    max-height: 500px;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  }

}




@keyframes satrapsf {
  0% {
    background-position: left 0px top 0px;
  }

  50% {
    background-position: left 1080px top 0px;
  }
}

@-webkit-keyframes satrapsf {
  0% {
    -webkit-background-position: left 0px top 0px;
  }

  50% {
    -webkit-background-position: left 1080px top 0px;
  }
}